import React from 'react'
import { Alert, Badge, Button } from 'react-bootstrap'
import { useGetCountriesQuery } from '../../../components/api'
// import { useGetCompaniesQuery } from '../../../components/api/AccountsApix'
import { SimpleTabs } from '../../../components/Form'
import Layout from '../../../components/Layout'
import MUIDataTable from '../../../components/MuiDatatableUtils'
import { unauthNavTo } from '../../../components/navigation'
import Spinner from '../../../components/spinner'
import { getLabelFromArray } from '../../../components/static'
import VendorDeleteParticipantModal from '../../../pages/app/kitchensink/vendorDeleteParticipant'
import {
  useGetCompaniesQuery,
  useGetuserDetailsQuery
} from '../../../utils/api'
import ParticipantsInviteCompanyModal from '../kitchensink/participantsInviteCompanyModal'

const Page = () => {
  // ANCHOR state
  const [selectedRowIndexes, setSelectedRowIndexes] = React.useState([])
  const [openDeleteParticipantModal, setOpenDeleteParticipantModal] =
    React.useState(false)
  const [selectedParticipants, setSelectedParticipants] = React.useState([])
  const [showInviteCompanyModal, setShowInviteCompanyModal] =
    React.useState(false)
  const [tabChanged, setTabChanged] = React.useState(false)
  const [tabSelectedIndex, setTabSelectedIndex] = React.useState(0)
  const [filteredData, setFilteredData] = React.useState('')

  const deselectAll = () => {
    setSelectedRowIndexes([])
  }

  // ANCHOR query
  const companiesQuery = useGetCompaniesQuery()
  const countriesQuery = useGetCountriesQuery()
  const { data: { _isVendor = false } = {} } = useGetuserDetailsQuery()

  React.useEffect(() => {})
  const goPage = (x) => {
    unauthNavTo.viewParticipantPage(x)
  }

  // ANCHOR functions

  const filterBaseOnTab = (e) => {
    const companyList = companiesQuery.data
    switch (e.target.innerText) {
      case 'All':
        setFilteredData(companyList)
        setTabSelectedIndex(0)
        break
      case 'Active':
        setFilteredData(companyList.filter((c) => c.status === 'active'))
        setTabSelectedIndex(1)
        break
      case 'Suspended/Declined':
        setFilteredData(companyList.filter((c) => c.status === 'suspended' || c.status === 'declined'))
        setTabSelectedIndex(3)
        break
      case 'Invited/Applied':
        setFilteredData(companyList.filter((c) => c.status === 'invited' || c.status === 'applied'))
        setTabSelectedIndex(2)
        break
      default:
        break
    }
    setTabChanged(true)
  }

  // ANCHOR React <>
  return (
    <>
      <Layout>
        <Layout.Title>
          <h5>Participants</h5>
          <Button
            variant="primary"
            className="d-flex align-items-center"
            onClick={() => setShowInviteCompanyModal(true)}
          >
            Invite Company
          </Button>
        </Layout.Title>
        <Layout.CardGrey>
          <SimpleTabs
            elements={[
              { label: 'All', onClick: filterBaseOnTab },
              { label: 'Active', onClick: filterBaseOnTab },
              { label: 'Invited/Applied', onClick: filterBaseOnTab },
              { label: 'Suspended/Declined', onClick: filterBaseOnTab }
            ]}
            selectedIndex={tabSelectedIndex}
            size="md"
          />
          <div className="card-body rounded-soft bg-white">
            <div className="container-fluid mb-4">
              {/* ANCHOR Table */}
              {companiesQuery.isLoading && (
                <div className="d-flex justify-content-center p-5">
                  <Spinner />
                </div>
              )}
              {companiesQuery.isError && (
                <Alert variant="danger" className="mb-0 mt-3 text-center">
                  {'' + companiesQuery.error.message}
                </Alert>
              )}
              {companiesQuery.isSuccess && (
                <MUIDataTable
                  columns={[
                    {
                      label: 'Status',
                      name: 'status',
                      options: {
                        customBodyRender: (status, meta) => {
                          return (
                            <Badge
                              pill
                              variant={`outline-${
                                status === 'active'
                                  ? 'success'
                                  : (status === 'pending' || status === 'applied')
                                  ? 'warning'
                                  : (status === 'suspended' || status === 'declined')
                                  ? 'danger'
                                  : 'dark'
                              }`}
                              className="text-capitalize"
                            >
                              {status}
                            </Badge>
                          )
                        }
                      }
                    },
                    { label: 'Company Name', name: 'companyName' },
                    { label: 'Nickname', name: 'nickName' },
                    {
                      label: 'Country',
                      name: 'country',
                      options: {
                        customBodyRender: (value) =>
                          countriesQuery.isSuccess &&
                          typeof value === 'string' &&
                          getLabelFromArray(
                            countriesQuery.data,
                            value.toLowerCase()
                          )
                      }
                    },
                    {
                      label: ' ',
                      name: 'x',
                      options: {
                        customBodyRender: (x, tableMeta) => {
                          return (
                            <div className="d-flex justify-content-end">
                              <Button
                                theme="outline-primary"
                                size="sm"
                                disabled={!(tableMeta.rowData[0] !== 'invited')}
                                onClick={() => {
                                  goPage(tableMeta.rowData[5])
                                }}
                              >
                                View
                              </Button>
                            </div>
                          )
                        }
                      }
                    },
                    {
                      lable: ' ',
                      name: 'id',
                      options: {
                        display: false
                      }
                    }
                  ]}
                  data={
                    tabChanged
                      ? filteredData
                      : companiesQuery.data
                        .filter((c) => c.nickName !== null)
                        .sort((a, b) =>
                          a.nickName.localeCompare(b.nickName, undefined, {
                            sensitivity: 'base'
                          })
                        )
                  }
                  options={{
                    pagination: true,
                    jumpToPage: false,
                    print: false,
                    search: true,
                    download: true,
                    sort: true,
                    filter: true,
                    viewColumns: true,
                    selectableRows: _isVendor ? 'multiple' : 'none',
                    elevation: 0,
                    rowsPerPage: 100,
                    rowsSelected: selectedRowIndexes,
                    sortOrder: {
                      name: 'companyName',
                      direction: 'asc'
                    },
                    onRowSelectionChange: (
                      currentRowsSelected,
                      allRowsSelected,
                      rowsSelected
                    ) => {
                      if (
                        selectedRowIndexes.join(',') !== rowsSelected.join(',')
                      ) {
                        setSelectedRowIndexes(rowsSelected)
                      }
                    },
                    customToolbarSelect: (selectedRows, displayData) => {
                      // defer loading selected SKUs for performance reasons...
                      const getSelectedParticipants = () =>
                        selectedRows.data.map(
                          (d) => displayData[d.index].data[5]
                        )
                      return (
                        <div className="custom-toolbar-select flex-mobile-column">
                          {_isVendor && (
                            <Button
                              variant="outline-primary"
                              className="btn-sm mr-2"
                              onClick={() => {
                                setOpenDeleteParticipantModal(true)
                                setSelectedParticipants(
                                  getSelectedParticipants()
                                )
                                deselectAll()
                              }}
                            >
                              Delete
                            </Button>
                          )}
                        </div>
                      )
                    }
                  }}
                />
              )}
            </div>
          </div>

          {/* { process.env.NODE_ENV !== 'production' && <pre>countriesQuery={JSON.stringify(countriesQuery, null, 2)}</pre>} */}
          {process.env.NODE_ENV !== 'production' && (
            <pre>companiesQuery={JSON.stringify(companiesQuery, null, 2)}</pre>
          )}
        </Layout.CardGrey>
      </Layout>

      {/* ANCHOR Modals */}
      <ParticipantsInviteCompanyModal
        openModal={showInviteCompanyModal}
        setOpenModal={setShowInviteCompanyModal}
      />
      <VendorDeleteParticipantModal
        openModal={openDeleteParticipantModal}
        setOpenModal={setOpenDeleteParticipantModal}
        participants={selectedParticipants}
      />
    </>
  )
}

export default Page
